<template>
    <div class="graph-info" align="center">
        <img :src="img.ydlj" v-for="img in tpData" style="width: 700px;height: 500px;margin-top: 5px"/>
    </div>
</template>
<script>
    import {getGraphImagesByClass} from "../../../../api/homeService";

    export default {
        data() {
            return {
                tpData: {}
            }
        },
        props: ['relatedInfoTp'],
        methods: {

        },
        mounted() {
            this.tpData = this.relatedInfoTp
        }
    }
</script>
<style lang="scss">
    .graph-info {
        span {
            font-size: 20px;
        }
    }

</style>
