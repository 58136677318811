<template>
    <div>
        <div id="mapDiv"></div>
        <div v-if="isShow" class="tip-tab" v-loading="loading">
            <div style="height:40px;">
                <div class="parkName">{{ this.parkPlace.djdymc }}</div>
                <div style="float: right">
                    <input type="button" class="navigator" value="关闭" v-if="showCloseButton" @click="closeTab"/>
                </div>
            </div>
            <ul class="destination-ul">
                <li @click="handleCurrMethod(index)" :class="{'active': selectPolicy == index}" v-for="(every, index) in navigation" :key="index">{{every.methods}}</li>
            </ul>
            <div id="destination">
<!--                <ul class="destination-ul">-->
<!--                    <li @click="handleCurrMethod(index)" :class="{'active': selectPolicy == index}" v-for="(every, index) in navigation" :key="index">{{every.methods}}</li>-->
<!--                </ul>-->
                <button @click="travel" class="go-here">到这里去</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                map: {},
                zoom: 13,   //地图的中心级别
                geocoder: '',  //geocoder用于获取用户的地址解析
                drivingRoute: '',  //驾车路线规划对象
                startLngLat: {},   //起点经纬度
                endLngLat: {},   //终点经纬度
                startTool: '',   //起点标注工具
                endTool: '',    //终点标注工具
                startIcon: 'http://lbs.tianditu.gov.cn/images/bus/start.png',  //起点标记图标
                endIcon: 'http://lbs.tianditu.gov.cn/images/bus/end.png',  //终点标记图标
                resultRoute: '',   //用于记录路线的搜索结果
                selectPolicy: 0,
                isShow: false,
                showCloseButton: true,
                loading: false,
                timeOut: '',
                polygons: [
                    {
                        lngLats: [
                            {latitude: 30.279806, longitude: 120.120216},
                            {latitude: 30.278146, longitude: 120.120246},
                            {latitude: 30.278166, longitude: 120.121526},
                            {latitude: 30.278376, longitude: 120.121546},
                            {latitude: 30.278416, longitude: 120.122056},
                            {latitude: 30.279826, longitude: 120.122036}
                        ],
                        color: 'red'
                    }
                ],
                array: [],
                navigation: [
                    {
                        methods: '最少时间',
                        curr: false
                    },
                    {
                        methods: '最短距离',
                        curr: false
                    },
                    {
                        methods: '避开高速',
                        curr: false
                    },
                    {
                        methods: '徒步',
                        curr: false
                    }
                ]
            }
        },
        mounted () {
            this.loadMap()
            setTimeout(this.position, 500)   //防止parkPlace还没传至该页面
        },
        props: ['parkPlace'],
        methods: {
            closeTab() {
                this.isShow = false
                this.map.clearOverLays()
                let marker = new T.Marker(new T.LngLat(this.parkPlace.longitude, this.parkPlace.latitude))
                this.map.centerAndZoom(new T.LngLat(this.parkPlace.longitude, this.parkPlace.latitude), this.zoom);
                marker.addEventListener("click", () => {
                    this.endLngLat = marker.getLngLat()
                    this.isShow = true
                })
                this.map.addOverLay(marker)
            },
            // backTab() {
            //     this.showBackButton = false
            //     this.showCloseButton = true
            //     let str = '<div style="height: 25px; margin-top: 5px">'+
            //             '<input type="radio" name="planType" value="0" checked="checked" />最少时间'+
            //             '<input type="radio" name="planType" value="1" />最短距离' +
            //             '<input type="radio" name="planType" value="2" />避开高速' +
            //             '<input type="radio" name="planType" value="3" />徒步</div>' +
            //             '<input type="button" @click="travel" class="navigator" value="到这里去"/>'
            //    document.getElementById("destination").innerHTML = str
            // },
            loadMap() {
                this.map = new T.Map("mapDiv")      //初始化地图
                let ctrl = new T.Control.MapType()    //添加地图类型控件（地形图，卫星图..）
                let scale = new T.Control.Scale()      //添加地图比例尺
                ctrl.setPosition(T_ANCHOR_BOTTOM_RIGHT)
                this.map.addControl(ctrl)
                this.map.addControl(scale)
                this.map.centerAndZoom(new T.LngLat(120.14969, 30.26240), this.zoom);   //设置当前地图的中心点位置
                this.map.enableScrollWheelZoom()    //允许鼠标双击放大地图
                this.geocoder = new T.Geocoder()   //获取geocoder，用于地址名称解析成经纬度
                let configuration = {
                    onSearchComplete: this.searchResult   //查询成功以后的回调函数 searchResult
                }
                this.drivingRoute = new T.DrivingRoute(this.map, configuration)     //初始化驾车线路对象
            },
            travel() {
                this.loading = true
                // this.showCloseButton = true;
                let lc = new T.LocalCity()      //定位当前位置
                this.map.clearOverLays()        //清楚地图上所有的标记
                lc.location((response) => {    //获取当前位置
                    this.startLngLat = response.lnglat
                    this.drivingRoute.setPolicy(this.selectPolicy)
                    this.drivingRoute.search(this.startLngLat,this.endLngLat)
                    // console.log(this.drivingRoute.getResults())
                    // if(this.drivingRoute.getResults()){
                    //
                    // }
                })
                this.timeOut = setTimeout(() => {
                    this.loading = false
                    document.getElementById('destination').innerHTML = '<p style="line-height: 24px">查询无结果</p>'
                },20000)
            },
            position() {  //根据景区的名字来定位经纬度
                this.map.clearOverLays()  //定位前，先清除地图上所有的标记
                /**
                 * 地图覆盖物
                 */
                let lngLats = this.polygons[0].lngLats
                let color = this.polygons[0].color
                for(let i = 0; i<lngLats.length; i++){
                    this.array.push(new T.LngLat(lngLats[i].longitude, lngLats[i].latitude))
                }
                // console.log(this.array)
                let polygons = new T.Polygon(this.array,{color: color, weight: 1})
                this.map.addOverLay(polygons)

                this.map.panTo(new T.LngLat(this.parkPlace.longitude, this.parkPlace.latitude), 16)
                let marker = new T.Marker(new T.LngLat(this.parkPlace.longitude, this.parkPlace.latitude))
                marker.addEventListener("click", () => {
                    this.endLngLat = marker.getLngLat()
                    this.isShow = true
                })
                this.map.addOverLay(marker)
            },
            searchResult(result) {      //根据起始点返回的查询结果函数  driverRoute的search方法返回的结果
                // console.log(result)
                clearTimeout(this.timeOut)
                this.createStartEndMarker(result)     //设置起始点标记
                this.resultRoute = result
                let routes = result.getNumPlans()     //返回的是方案的个数
                for(let i = 0; i < routes; i++){
                    let plan = result.getPlan(i)      //根据方案获取计划
                    let hours = plan.getDuration()/3600
                    let titleStr = ''
                    let describeStr = ''
                    if(this.selectPolicy != 3 ) {
                        titleStr = '<p style="color:#4D4D4D;line-height:24px;">时间：' + hours.toFixed(2) + '小时，距离：' + Math.round(plan.getDistance()) + '公里</p>'
                    }else{
                        titleStr = '<p style="color:#4D4D4D;line-height:24px;">时间：' + (hours*12).toFixed(2) + '小时，距离：' + Math.round(plan.getDistance()) + '公里</p>'
                    }
                    let numRoute = plan.getNumRoutes()    //返回该方案包含的线路的个数
                    for(let j = 0; j < numRoute; j++){
                        let route = plan.getRoute(j)
                        describeStr += '<div style="color: #000; font-size: 14px; line-height:20px;background-color:#F0F7FA;padding:5px 15px;border-top:1px solid #E5E5E5;border-bottom:1px solid #E5E5E5;">'+(j+1)+':'+route.getDescription()+'</div>'

                        let numStepStr = ''
                        let numSteps = route.getNumSteps()    //获取该段线路包含的关键点个数
                        for(let m = 0; m < numSteps; m++){
                            let step = route.getStep(m)
                            numStepStr += '<div style="color:#4C4C4C;line-height:20px;padding:2px 15px;">'+(m+1)+'):'+step.getDescription()+'</div>'    //关键点描述文本
                        }
                        describeStr += numStepStr
                    }
                    document.getElementById("destination").innerHTML = titleStr + '<div style="margin-top:6px;border:1px solid #E5E5E5;border-top:none;">' + describeStr + '</div>'
                    this.createRoute(plan.getPath())        //绘制路线图
                    this.map.setViewport(plan.getPath())      //用最佳比例尺来显示结果
                    this.loading = false
                }
            },
            createStartEndMarker(result){      //为查询结果添加起点和终点标记
                let startMarker = new T.Marker(result.getStart(), {
                    icon: new T.Icon({
                        iconUrl: this.startIcon,
                        iconSize: new T.Point(44,34),
                        iconAnchor: new T.Point(12,31)
                    })
                })
                this.map.addOverLay(startMarker)
                let endMarker = new T.Marker(result.getEnd(), {
                    icon: new T.Icon({
                        iconUrl: this.endIcon,
                        iconSize: new T.Point(44,34),
                        iconAnchor: new T.Point(12,31)
                    })
                })
                this.map.addOverLay(endMarker)
            },
            createRoute(Inglats, lineColor) {    //绘制起点到终点的线路 @param1:Inglats--经纬度集合
                let line = new T.Polyline(Inglats, {color: "#2C64A7", weight: 5, opacity: 0.9})
                this.map.addOverLay(line)
            },
            handleCurrMethod(index) {
                this.selectPolicy = index
                this.travel()
            }
        }
    }
</script>

<style scoped>
    #mapDiv{
        height: 65vh;
        width: 100%;
        position: absolute;
        z-index: 1;
    }

    .tip-tab{
        position: absolute;
        width: 300px;
        background-color: #fff;
        z-index: 2;
        animation: tipTap-flash 1s;
        overflow: scroll;
        height: 65vh;
    }

    .parkName{
        padding-top: 10px;
        font-size:16px;
        color: #000;
        font-weight:bold;
        float: left;
    }

    .navigator{
        margin-top: 6px;
        background-color: #049CE5;
        border: none;
        display: block;
        cursor: pointer;
        text-align: center;
        color: #FFF;
        width: 80px;
        height: 24px;
        font-size: 13px;
        float: right;
    }
    .go-here{
        margin-top:12px;
        background-color: #049CE5;
        border: none;
        display: block;
        cursor: pointer;
        text-align: center;
        color: #FFF;
        width: 100%;
        height: 32px;
        font-size:14px;
    }
    .destination-ul{
        display:flex;
        height:32px;
        line-height:32px;
        border:1px solid #E5E5E5
    }
    .destination-ul li{
        flex:4;
        font-size:14px;
        text-align:center;
        border-right:1px solid #E5E5E5
    }
    .destination-ul li:hover,.destination-ul li.active{
        cursor:pointer;
        color:#049CE5;
        height:33px;
        border-top:2px solid #049CE5;
        background-color:#fff;
        margin-top:-2px;
    }
    .destination-ul li:last-child{
        border-right:none;
    }
    #destination .dest-text{
        border:1px solid #E5E5E5;
        padding:10px 15px;
    }

    @keyframes tipTap-flash {
        from{
            /*transform: translate(-250px,0);*/
            opacity: 0;
        }to{
            /*transform: translate(0,0);*/
            opacity: 1;
                 }
    }
</style>
