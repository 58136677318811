<template>
  <div class="kpzs-menu">
    <div class="card-box">

      <div class="item-card">
        <!-- <div class="center-text" v-for="(item, index) of menudata" :key="index" v-show="nowIndex === index" @click="toDatil(item)">
          - {{item.label}} -
        </div> -->
        <div class="text-list">
          <ul>
            <li v-for="(item, index) of menudata" :key="index" :class="{active:nowIndex === index}" class="list-item"
                @click="select(index,item)" @mouseover="mouseOver(index)">
				<span style="display: inline-block;width:80px;">
					<i class="icon-line"></i>
				</span>	              
			  <P>{{ item.label }}</P>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    menulist: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      nowIndex: 0,
      menudata: this.menulist,

    }
  },
  mounted() {

  },
  methods: {
    select(index, item) {
      // this.nowIndex=index;
      let url = item.link+'&cd='+item.label;
      this.$router.push({
        path: url
      });
    },
    toDatil(item) {
      let url = item.link
      this.$router.push({
        path: url
      });
    },
    mouseOver(index) {
      this.nowIndex = index;
    }
  }
}

</script>
<style lang="scss">
.kpzs-menu {
  .card-box {
    .item-card {
      width: 100%;
      // height:280px;
      margin-bottom: 20px;
      display: flex;
      background: url(../../../../assets/images/kpzs/pic-zygk.png) no-repeat center center;
      background-size: 100% 100%;

      .center-text {
        width: 360px;
        height: 42px;
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 30px;
        text-align: center;
        background: url(../../../../assets/images/kpzs/text-bottom.png) no-repeat center bottom;
        margin: 122px 100px 116px 420px;
        cursor: pointer;
      }

      .text-list {
        width: 320px;
        // height: 240px;
        margin-left: auto;
        margin-top: 10px;
        margin-bottom: 10px;  
        ul {
          width: 100%;
          height: 100%;

          li {
            width: 100%;
            // height: 60px;
			padding: 18px 0px;
            line-height:22px;
            text-align: right;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.5);
            padding-right: 41px;
            box-sizing: border-box;
            cursor: pointer;
			display: flex;
			flex-direction: row;
			align-items: center;
			.icon-line {
			  // visibility: hidden;
			  display: inline-block;
			  width: 30px;
			  height: 2px;
			  // background: #FFFFFF;
			  margin: 0px 10px 8px 0px;
			  
			}
			p{	
				flex: 1;
				text-align: right;
			}
          }

          li.active {
			line-height:28px;  
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            background: linear-gradient(to left, rgba(0, 128, 255, 0.6), rgba(0, 128, 255, 0));

            .icon-line {			  
              display: inline-block;
              width: 30px;
              height: 2px;
              background: #FFFFFF;
              margin: 0px 10px 8px 0px;
            }
          }

          li:hover {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            background: linear-gradient(to left, rgba(0, 128, 255, 0.6), rgba(0, 128, 255, 0));

            .icon-line {
              display: inline-block;
              width: 30px;
              height: 2px;
              background: #FFFFFF;
              margin: 0px 10px 8px 0px;
            }
          }
        }
      }
    }

  }
}

</style>
