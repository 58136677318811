<template>
    <div class="register-panel">
        <div class="panel">
            <p class="title">
                <span>自然资源登记簿</span>
            </p>
            <div class="list">
                <el-row>
                    <el-col :span="8">
                        <span>登记单元名称：</span>
                    </el-col>
                    <el-col :span="16">
                        <span>{{djdymc}}</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <span>登记单元号：</span>
                    </el-col>
                    <el-col :span="16">
                        <span>{{qsxx.djdyh}}</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <span>登记单元类型：</span>
                    </el-col>
                    <el-col :span="16">
                        <span>{{qsxx.djdylx}}</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <span>登记机构：</span>
                    </el-col>
                    <el-col :span="16">
                        <span>{{qsxx.djg}}</span>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        name: 'register-panel',
        data() {
            return {

            }
        },
        methods: {

        },
        props:{
            qsxx:{},
            djdymc:'',
        },
        mounted() {

        }


    }
</script>

<style lang="scss">
    .register-panel {
        padding:100px 0;
        box-shadow:0px 6px 18px #999;
        .panel{
            width:600px;
            margin:0 auto;
            min-height:200px;
            font-family:'楷体';
            .title{
                font-size:36px;
                text-align:center;
                margin-bottom:80px;
                letter-spacing:10px;
            }
            .list{
                margin-left:155px;
                .el-row{
                    margin-bottom:22px;
                    .el-col{
                        line-height:30px;
                        font-size:20px;
                        font-family:'Microsoft YaHei';
                    }
                }
            }

        }
    }
</style>
