<template>
    <div class="popular-science-knowledge-card">
        <!-- 资源概况模版       -->
        <zygk-infor v-if="currentMenu == '资源概况'" :zrzyDyId="menuItem.zrzyDyId"></zygk-infor>
        <!-- 自然环境、动物模版        -->
        <zrhj-infor
            v-else-if="currentMenu == '2' && renderComponent"
            :tabId="menuItem.tabId"
            :zrzyDyId="menuItem.zrzyDyId"
            :zrhj-name="modelName"
        >
        </zrhj-infor>
        <!-- 历史人文模版       -->
        <lvty-infor
            v-else-if="(currentMenu == '5'||currentMenu == '1'||currentMenu == '3') && renderComponent"
            :tabId="menuItem.tabId"
            :zrzyDyId="menuItem.zrzyDyId"
            :lvty-name="modelName"
        >
        </lvty-infor>

        <el-empty v-else description="暂无模版"></el-empty>

    </div>
</template>
<script>
    import zygkInfor from '../../../../views/kpzsInfor/zygkInfor'
    import zrhjInfor from '../../../../views/kpzsInfor/zrhjInfor'
    import lvtyInfor from '../../../../views/kpzsInfor/lvtyInfor'

    export default {
        components: {
            zygkInfor,zrhjInfor,lvtyInfor,
        },
        props: {
            menulist: {
                type: Array,
                default: () => []
            },
            knowledgeCurrentMenu:{
                type: String,
                default: () => '资源概况'
            },
            menuItem:{
                default: () => {}
            },
            renderComponent: {
              type: Boolean,
              default: () => false
            },
            modelName: {
              type: String,
              default: () => ''
            },
        },
        data() {
            return {
                stepsVisible: true, // 右侧锚点是否显示
                isFixed: false, // 右侧锚点样式判断
                currentMenu: '资源概况',
                tabId:'',
                zrzyDyId:'',
                showSteps:false,
            }
        },
        mounted() {

        },
        computed:{

        },
        watch: {
            'knowledgeCurrentMenu': {
                handler(val) {
                    this.currentMenu = this.knowledgeCurrentMenu;
                    this.tabId = this.menuItem?.tabId;
                }
            }
        },
        methods: {
            // 锚点跳转
            jump(item,index) {
                console.log(item)
                this.currentMenu = item.mklx;
                this.tabId = item.tabId;
                this.zrzyDyId = item.zrzyDyId;
            },

        }
    }
</script>

<style lang="less">
    .popular-science-knowledge-card {
        min-height: 320px;
        position: relative;
        .steps:hover {
            border-color: #e8e8e8;
            background-color: rgba(255, 255, 255, 0.8);

            .anchor {
                .text {
                    opacity: 1;
                }
            }
        }

        .steps.fixed {
            position: fixed;
            right: 30px;
            top: 50px;
        }

    }
</style>

