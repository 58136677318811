<template>
    <div class="owner-situ nature-situ">
        <el-form :model="zrzk" label-width="260px" ref="form">
            <el-form-item>
                <span slot="label">
                    登记单元总面积（公顷）
                </span>
                <span class="value-span">{{tpData.qszk.djdyzmj |defaultValue}}</span>
            </el-form-item>
            <el-form-item>
                <span slot="label">
                    单元内自然资源总面积（公顷）
                </span>
                <span class="value-span">{{zrzk.zrzyzmj |defaultValue}}</span>
            </el-form-item>

            <el-form-item class="sper">
                <span class="qaz" slot="label">
                    其中
                </span>
                <el-row class="sper-row">
                    <el-col :span="8">
                        <el-form-item>
                            <span slot="label">水流</span>
                            <span>{{zrzk.slmj |defaultValue}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item>
                            <span slot="label">湿地</span>
                            <span>{{zrzk.sdmj |defaultValue}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item>
                            <span slot="label">森林</span>
                            <span>{{zrzk.senlmj |defaultValue}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item>
                            <span slot="label">草原</span>
                            <span>{{zrzk.cymj |defaultValue}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item>
                            <span slot="label">荒地</span>
                            <span>{{zrzk.hdmj |defaultValue}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item class="no-border">
                            <span slot="label">其他</span>
                            <span>{{zrzk.qtmj |defaultValue}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item class="w">
                <span slot="label">
                    单元内耕地、建设用地等非自然资源总面积（公顷）
                </span>
                <span class="value-span">{{zrzk.fzrzymj |defaultValue}}</span>
            </el-form-item>
        </el-form>
        <p class="column">
            <el-popover
                    placement="bottom-start"
                    popper-class="nature-situ-pop"
                    trigger="hover"
                    v-model="popVisible">
                <ul class="pop-menu">
                    <li :key="menu.id" @click="handlePopMenu(menu.id, menu.label)" v-for="menu in popMenus"
                        v-if="menu.show">
                        {{menu.label |defaultValue}}
                    </li>
                </ul>
                <img class="anchor" slot="reference" src="../../../../assets/images/icon_mark.png"/>
            </el-popover>
            <span>{{currentMenu}}</span>
        </p>
        <div class="table-con" ref="box">

            <div :style="{display:tpData.sl===undefined?'none':''}" class="table-row" ref="sl">
                <p class="title"> 水流</p>
                <el-table
                        :data="tpData.sl"
                        border
                        style="width: 100%">
                    <el-table-column
                            align="center"
                            label="水流类型"
                            prop="sllx">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.sllx)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="名称"
                            prop="slmc">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.slmc)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="包含图斑数量"
                            prop="tbsl">
                        <template slot-scope="scope">
                            <span>{{scope.row.tbsl |defaultValue }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="河流起点、讫点"
                            prop="hlqzd">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.hlqzd)">-</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="河流长度(千米)"
                            prop="hlcd">
                        <template slot-scope="scope">
                            <span>{{scope.row.hlcd |defaultValue }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="水面面积（公顷）"
                            prop="smmj">
                        <template slot-scope="scope">
                            <span>{{scope.row.smmj |defaultValue}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="河道等级"
                            prop="hddj">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.hddj)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="dnpjjll">
                        <template slot="header">
                            <span>多年平均径流量(亿立方米)</span>
                        </template>
                        <template slot-scope="scope">
                            <span>{{scope.row.dnpjjll |defaultValue}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="水质">
                        <el-table-column align="center">
                            <template slot="header">
                                <span>Ⅰ类</span>
                            </template>
                            <template slot-scope="scope">
                                <span>{{scope.row.sz1|defaultValue}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center">
                            <template slot="header">
                                <span>Ⅱ类</span>
                            </template>
                            <template slot-scope="scope">
                                <span>{{scope.row.sz2|defaultValue}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center">
                            <template slot="header">
                                <span>Ⅲ类</span>
                            </template>
                            <template slot-scope="scope">
                                <span>{{scope.row.sz3|defaultValue}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center">
                            <template slot="header">
                                <span>Ⅳ类</span>
                            </template>
                            <template slot-scope="scope">
                                <span>{{scope.row.sz4|defaultValue}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center">
                            <template slot="header">
                                <span>Ⅴ类</span>
                            </template>
                            <template slot-scope="scope">
                                <span>{{scope.row.sz5|defaultValue}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center">
                            <template slot="header">
                                <span>劣VI类</span>
                            </template>
                            <template slot-scope="scope">
                                <span>{{scope.row.sz6|defaultValue}}</span>
                            </template>
                        </el-table-column>

                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="storage">
                        <template slot="header">
                            <span>年初蓄水量(亿立方米)</span>
                        </template>
                        <template slot-scope="scope">
                            <span>{{scope.row.ncxsl |defaultValue}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div :style="{display:tpData.sd===undefined?'none':''}" class="table-row" ref="sd">
                <p class="title"> 湿地</p>
                <el-table
                        :data="tpData.sd"
                        border
                        style="width: 100%">
                    <el-table-column
                            align="center"
                            label="湿地类型"
                            prop="sdlx">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.sdlx)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="包含图斑数量"
                            prop="tbsl"
                            width="130">
                        <template slot-scope="scope">
                            <span>{{scope.row.tbsl |defaultValue }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="总面积（公顷）"
                            prop="zmj">
                        <template slot-scope="scope">
                            <span>{{scope.row.zmj |defaultValue}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="植被类型"
                            prop="zblx">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.zblx)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="植被面积（公顷）"
                            prop="zbmj">
                        <template slot-scope="scope">
                            <span>{{scope.row.zbmj |defaultValue}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="主要优势植物种(建群种)"
                            prop="zwz"
                            width="130">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.zwz)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="国家及省级重点保护的主要湿地鸟类"
                            prop="nl"
                            width="170">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.nl)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="szlb">
                        <template slot="header">
                            <span>水质类别</span>
                        </template>
                        <template slot-scope="scope">
                            <span>{{scope.row.szlb |defaultValue}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="水源补给状况"
                            prop="sybgqk">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.sybgqk)"></span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div :style="{display:tpData.senl===undefined?'none':''}" class="table-row" ref="senl">
                <p class="title"> 森林</p>
                <el-table
                        :data="tpData.senl"
                        border
                        style="width: 100%">
                    <el-table-column
                            align="center"
                            label="森林类型"
                            prop="sllx">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.sllx)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="包含图斑数量"
                            prop="tbsl"
                            width="130">
                        <template slot-scope="scope">
                            <span>{{scope.row.tbsl |defaultValue }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="面积（公顷）"
                            prop="mj">
                        <template slot-scope="scope">
                            <span>{{scope.row.mj |defaultValue}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="主导功能"
                            prop="zdgn">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.zdgn)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="主要树种"
                            prop="zysz">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.zysz)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="林种"
                            prop="lz">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.lz)">林种</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="zxjl">
                        <template slot="header">
                            <span>总蓄积量（立方米）</span>
                        </template>
                        <template slot-scope="scope">
                            <span>{{scope.row.zxjl |defaultValue}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div :style="{display:tpData.cy===undefined?'none':''}" class="table-row" ref="cy">
                <p class="title"> 草原</p>
                <el-table
                        :data="tpData.cy"
                        border
                        style="width: 100%">
                    <el-table-column
                            align="center"
                            label="草地类型"
                            prop="cdlx">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.cdlx)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="包含图斑数量"
                            prop="tbsl"
                            width="130">
                        <template slot-scope="scope">
                            <span>{{scope.row.tbsl |defaultValue }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="面积（公顷）"
                            prop="mj">
                        <template slot-scope="scope">
                            <span>{{scope.row.mj |defaultValue}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="草原类型"
                            prop="cylx">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.cylx)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="草原质量等级"
                            prop="zldj">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.zldj)"></span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div :style="{display:tpData.hd===undefined?'none':''}" class="table-row" ref="hd">
                <p class="title"> 荒地</p>
                <el-table
                        :data="tpData.hd"
                        border
                        style="width: 100%">
                    <el-table-column
                            align="center"
                            label="荒地类型"
                            prop="hdlx">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.hdlx)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="包含图斑数量"
                            prop="tbsl">
                        <template slot-scope="scope">
                            <span>{{scope.row.tbsl |defaultValue }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="面积（公顷）"
                            prop="mj">
                        <template slot-scope="scope">
                            <span>{{scope.row.mj |defaultValue}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div :style="{display:tpData.hy===undefined?'none':''}" class="table-row" ref="hy">
                <p class="title"> 海域</p>
                <el-table
                        :data="tpData.hy"
                        border
                        style="width: 100%">
                    <el-table-column
                            align="center"
                            label="海域面积（公顷）"
                            prop="hymj">
                        <template slot-scope="scope">
                            <span>{{scope.row.hymj |defaultValue}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="海域等级"
                            prop="hydb">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.hydb)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="大陆海岸线长度(千米)"
                            prop="dlhaxcd">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.dlhaxcd)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="有居民海岛海岸线长度(千米)"
                            prop="yjmhdhaxcd">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.yjmhdhaxcd)"></span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div :style="{display:tpData.wjmhd===undefined?'none':''}" class="table-row" ref="wjmhd">
                <p class="title"> 无居民海岛</p>
                <el-table
                        :data="tpData.wjmhd"
                        border
                        style="width: 100%">
                    <el-table-column
                            align="center"
                            label="海岛名称"
                            prop="hdmc">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.hdmc)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="海岛位置"
                            prop="hdwz">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.hdwz)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="海岛面积(公顷)">
                        <template slot-scope="scope">
                            <span>{{scope.row.hdmj |defaultValue}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="海岛类型"
                            prop="hdlx">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.hdlx)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            width="220">
                        <template slot="header">
                            <span>海岛高程(米)<br/>(最高点高程/平均高程)</span>
                        </template>
                        <template slot-scope="scope">
                            <span>{{scope.row.zgdgc |defaultValue}}/{{scope.row.pjgc |defaultValue}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="植被覆盖情况"
                            prop="zbfgqk"
                            width="160">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.zbfgqk)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="海岸线长度(千米)"
                            prop="axcd">
                        <template slot-scope="scope">
                            <span>{{scope.row.axcd |defaultValue}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div :style="{display:'none'}" class="table-row" ref="kczy">
                <p class="title"> 探明储量的矿产资源</p>
                <el-table
                        :data="tpData.kczy"
                        border
                        style="width: 100%">
                    <el-table-column
                            align="center"
                            label="资源类型"
                            prop="zylx">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.zylx)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="区块编号"
                            prop="qkbh">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.qkbh)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="矿区地址"
                            prop="kqdz">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.kqdz)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="储存估算基准日"
                            prop="clgsjzr">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.clgsjzr)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="矿区/油气田总面积"
                            prop="yqtzmj">
                        <template slot-scope="scope">
                            <span>{{scope.row.yqtzmj |defaultValue}}公顷</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="clgsfwmj">
                        <template slot="header">
                            <span>储量估算范围面积</span>
                        </template>
                        <template slot-scope="scope">
                            <span>{{scope.row.clgsfwmj |defaultValue}}公顷</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="矿产组合"
                            prop="kczh">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.kczh)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="固体矿产">
                        <el-table-column
                                label="推断资源量"
                                prop="gtTdzyl">
                        </el-table-column>
                        <el-table-column
                                label="控制资源量"
                                prop="gtKzzyl">
                        </el-table-column>
                        <el-table-column
                                label="探明资源量"
                                prop="gtTmzyl">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="油气">
                        <el-table-column
                                label="探明地质储量"
                                prop="ytTmdzcl">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="主要组成平均品位"
                            prop="zyzfpjyw">
                        <template slot-scope="scope">
                            <span v-html="$options.filters.defaultValue(scope.row.zyzfpjyw)"></span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-row attach">
                <div class="left">
                    <span>附记</span>
                </div>
                <div class="right">
                    <el-input :disabled="true" type="textarea" v-model="zrzk.fj"></el-input>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        filters: {
            defaultValue(val) {
                if (val === '' || val === undefined || val === null || val === 'null' || val === 'undefined') {
                    return '-'
                }else if(typeof(val) === "number"){
                    if (String(val).indexOf('.') !== 1) {
                        const realVal = parseFloat(val).toFixed(2);
                        return realVal;
                    } else {
                        let x = String(val).indexOf('.') + 1; //小数点的位置
                        let y = String(val).length - x; //小数的位数
                        //返回小数点后的个数
                        return y > 2 ? val : parseFloat(val).toFixed(2);
                    }
                }else{
                    return val;
                }
            },

        },
        data() {
            return {
                zrzk: {},
                tpData: {},
                currentMenu: '水流',
                popMenus: [
                    {
                        id: 'sl',
                        label: '水流',
                        show: false,
                        top: 0,
                    },
                    {
                        id: 'sd',
                        label: '湿地',
                        show: false,
                        top: 0,
                    },
                    {
                        id: 'senl',
                        label: '森林',
                        show: false,
                        top: 0,
                    },
                    {
                        id: 'cy',
                        label: '草原',
                        show: false,
                        top: 0,
                    },
                    {
                        id: 'hd',
                        label: '荒地',
                        show: false,
                        top: 0,
                    },
                    {
                        id: 'hy',
                        label: '海域',
                        show: false,
                        top: 0,
                    },
                    {
                        id: 'wjmhd',
                        label: '无居民海岛',
                        show: false,
                        top: 0,
                    },
                    // {
                    //     id: 'kczy',
                    //     label: '探明储量的矿产资源',
                    //     show: false,
                    //     top: 0,
                    // }
                ],
                popVisible: false,
            }
        },
        props: ['natureSituDetails'],
        methods: {
            // 处理popMenu菜单
            handlePopMenu(id, label) {
                this.$refs.box.scrollTop = this.$refs[id].offsetTop
                this.currentMenu = label
                this.popVisible = false
                console.log(this.$refs[id].offsetTop)

            },
            tableWheel() {
                // 获取并处理 scrollTop与offsetTop
                let scrollTop = this.$refs.box.scrollTop
                // console.log(scrollTop)
                if (scrollTop === 0) {
                    this.currentMenu = this.popMenus[0].label
                } else {
                    for (let i = 0; i < this.popMenus.length; i++) {
                        if (scrollTop - this.popMenus[i].top >= 0 && this.popMenus[i].show) {
                            this.currentMenu = this.popMenus[i].label
                        } else {
                            break;
                        }
                    }
                }

            }
        },
        created() {
            this.tpData = this.natureSituDetails
            this.zrzk = this.natureSituDetails.zrzk
        },
        mounted() {
           this.$nextTick(async () => { // 表格还未加载完
               // 添加是否显示及位置数据
               for (let i = 0; i < this.popMenus.length; i++) {
                   if (this.popMenus[i].id in this.natureSituDetails) {
                       this.popMenus[i].show = true
                       this.popMenus[i].top = this.$refs[this.popMenus[i].id].offsetTop
                       console.log(this.$refs['sd'].offsetTop)
                   }
               }
               //设置默认第一项
               for (let i = 0; i < this.popMenus.length; i++) {
                   if (this.popMenus[i].show) {
                       this.currentMenu = this.popMenus[i].label
                       break;
                   }
               }
               // 监听表格box滚动事件
               var box = this.$refs.box
               // box.addEventListener('scroll', this.tableWheel)
               box.onmousewheel = this.tableWheel
           })
        },

    }
</script>
<style></style>
<style lang="scss">
    .nature-situ {
        .el-form {
            margin-bottom: 12px;

            .sper > .el-form-item__label {
                width: 259px !important;
                line-height: 101px;
                border-bottom: 1px solid #D9D9D9;
                border-right: none;
            }

            .sper > .el-form-item__content {
                margin-left: 259px !important;
            }

            .sper {
                border-bottom: none;

                .sper-row {
                    .el-form-item__label {
                        text-align: center;
                        width: 100px !important;
                        background-color: transparent;
                        border-left: 1px solid #D9D9D9;
                    }
                }

            }

            .w {
                .el-form-item__label {
                    width: 420px !important;
                }
            }
        }

        .column {
            font-size: 18px;
            line-height: 50px;
            padding: 0 20px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.08);

            .anchor {
                margin: 16px 10px 0 0;
                cursor: pointer;
            }

            span {
                font-size: 16px;
                color: #039BE5;
                font-weight: bold;
            }
        }

        .table-con {
            /*padding: 20px;*/
            max-height: 500px;
            overflow-y: auto;
            position: relative;

            .table-row {
                font-size: 16px;
                color: #666;
                margin-bottom: 10px;

                .title {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: rgba(51, 51, 51, 1);
                    line-height: 40px;
                }

                .el-icon-question {
                    font-size: 16px;
                    color: #F39920;
                    cursor: pointer;
                }

                .el-table {
                    border: 1px solid #EBEEF5;
                    border-top: none;
                    border-bottom: none;

                    th {
                        background-color: #f2f2f2;
                    }

                    td, th {
                        color: #666;
                        padding: 13px 0;
                        font-size: 16px;

                        .cell {
                            padding: 0;
                            white-space: pre-wrap;
                            span{
                                white-space: pre-wrap;
                            }
                            a {
                                color: #136ec2;
                            }

                            a:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .el-table--border::after, .el-table::before {
                    display: none;
                }
            }

            .attach {
                position: relative;
                margin-top: 25px;

                .left {
                    width: 70px;
                    position: absolute;
                    top: 50%;
                    margin-top: -12px;
                    text-align: center;
                    border-right: none;
                }

                .right {
                    margin: -1px -1px -1px 71px;
                }
            }
        }
    }

    .nature-situ-pop {
        max-height: 220px;
        max-width: 200px;
        overflow-y: auto;

        .pop-menu {
            li {
                font-size: 16px;
                padding-left: 10px;
                line-height: 38px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            li:hover {
                color: #039BE5;
                cursor: pointer;
                background-color: #f5f5f5;
            }
        }
    }

</style>
