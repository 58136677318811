<template>
    <div class="nature-env">
        <table class="table-info">
            <tr :key=index v-for="(list, index) of dataTab">
                <td class="left" width="50">
                    <span class="icon">
                        <img :src="list.pctb" style="width: 40px;height: 40px">
                        {{list.mc}}</span>
                </td>
                <td class="right">
                    <ul class="img-list">
                        <li :key="index" v-for="(img, index) of list.data" @click="detail(img.sjly,img.sjid)" :title="img.title">
                            <img :src="img.ydlj==null?img.bdlj:img.ydlj"/><br/>
                            <span class="wz">{{img.title}}</span>
                        </li>
                    </ul>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
    import {getTabDataGroupByClass} from "../../../../api/homeService";

    export default {
        props: {
            tabId: {
                type: String,
                default: () => ''
            }, zrzyId: {
                type: String,
                default: () => ''
            },
            data: []
        },
        data() {
            return {
                dataTab: []
            }
        },
        mounted() {
            this.getDyxxById()
        },
        methods: {
            getDyxxById() {
                const dy = {'zrzyid': this.zrzyId, 'id': this.tabId}
                getTabDataGroupByClass(dy).then(res => {
                    if (res.code === '0') {
                        this.dataTab = res.data;
                    }
                })
            },
            /**
             * 根据当前单元号跳转
             * @param currentId
             */
            detail(type, currentId) {
                if (type === 'dyxx') {
                    this.$router.push({
                        name: 'infoDetail',
                        params: {
                          zrzyDyId: currentId
                        }
                    })
                    this.$router.go(0);
                } else {
                    this.$router.push({
                        name: 'newsDetail',
                        title: '历史人文',
                        query: {
                            id: currentId
                        }
                    })
                }

            }
        }
    }
</script>
<style lang="scss">
    .wz {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .nature-env {
        padding: 20px;
        background: url(../../../../assets/images/nature-env-bg1.png) center no-repeat;

        .table-info {
            width: 100%;
            border-collapse: collapse;
            background-color: rgba(255, 255, 255, .5);

            td {
                position: relative;
                border: 1px solid #D9D9D9;
            }

            td.left {
                text-align: center;
                background-color: #F2F2F2;

                .icon {
                    display: inline-block;
                    margin: 17px 0;
                    padding-top: 36px;
                    background: center top no-repeat;
                    width: 50px;
                    font-size: 18px;
                    color: #666;
                }

                .icon.water {
                    background-image: url(../../../../assets/images/icon_water.png);
                }

                .icon.landform {
                    background-image: url(../../../../assets/images/icon_landform.png);
                }

                .icon.climate {
                    background-image: url(../../../../assets/images/icon_climate.png);
                }

                .icon.animal {
                    background-image: url(../../../../assets/images/icon_animal.png);
                }

                .icon.plant {
                    background-image: url(../../../../assets/images/icon_plant.png);
                }
            }

            td.right {
                .img-list {
                    overflow: hidden;
                    padding-bottom: 12px;

                    li {
                        float: left;
                        width: 180px;
                        margin: 12px 20px 0;
                        text-align: center;

                        img {
                            width: 180px;
                            height: 120px;
                        }

                        span {
                            font-size: 12px;
                            line-height: 22px;
                        }
                    }
                }

            }
        }
    }

</style>
