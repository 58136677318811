<template>
    <div class="resource-situation">
        <p class="para" v-html="content"></p>
<!--        <el-row class="menu-title">-->
<!--            <el-col class="title">-->
<!--                <span>用户点评</span>-->
<!--            </el-col>-->
<!--        </el-row>-->
<!--        <div class="info-comment">-->
<!--             评论区域-->
<!--            <comment-changyan :post-id="postId"></comment-changyan>-->
<!--        </div>-->
    </div>
</template>
<script>
    // import commentChangyan from "@/plugins/changyan/commentChangyan"
    import {getDyTab} from "@/api/homeService"

    export default {
        components: {
            // commentChangyan
        },
        data() {
            return {
                content: '',
                postId: ''
            }
        },
        methods: {
        },
        props: ['gs'],
        // created() {
        //     this.content = this.gs;
        // },
        watch: {
            'gs': {
                handler(val) {
                    if (val != undefined) {
                        this.content = this.gs;
                    }
                },
                immediate: true,
            }
        },
        mounted() {
            let id = this.$route.query.id;
            // this.getImg(id);
            /**
             * postId用来记录畅言云评的sourceId，将不同的内容用不同的sourceId区分出来
             */
            this.postId = id
        }
    }
</script>
<style lang="scss">
    .resource-situation {
        .para {
            font-size: 18px;
            line-height: 32px;
            color: #333;
            text-indent: 36px;
            text-align: justify;
            margin-bottom: 20px;
        }

        .menu-title {
            .title {
                margin-bottom: 0 !important;
            }
        }

        .info-comment {
            .comment {
                padding: 0 20px;
                margin-bottom: 20px;
                border-bottom: 1px dashed #D9D9D9;
            }

            .user, .text, .pic {
                margin-bottom: 20px;
            }

            .user {
                line-height: 28px;

                .user-info {
                    cursor: pointer;
                }

                .avatar {
                    float: left;
                    width: 60px;
                    height: 60px;
                    margin-right: 16px;
                    border-radius: 50%;
                }

                .intor {
                    float: left;
                    padding-top: 4px;

                    .name {
                        font-size: 18px;
                        color: #333;
                    }

                    .location {
                        font-size: 14px;
                        color: #9EA4AF;
                    }
                }

                .time {
                    font-size: 16px;
                    color: #656589;
                }
            }

            .text {
                font-size: 18px;
                color: #6A6A77;
                line-height: 28px;
            }

            .pic {
                .pic-row {
                    .el-col {
                        height: 100px;
                        margin-bottom: 10px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .replay {
                .el-button {
                    position: absolute;
                    bottom: 30px;
                    right: 0;
                    color: #fff;
                    font-size: 18px;
                    padding: 7px 22px;
                    background-color: #0078FF;
                }
            }
        }

        .more-comment {
            margin-bottom: 10px;

            .el-col {
                text-align: center;

                span {
                    cursor: pointer;
                    font-size: 18px;
                    color: #0078FF;

                    .el-icon-d-arrow-right {
                        margin-left: 5px;
                        font-size: 20px;
                        vertical-align: -1px;
                        transform: rotate(90deg);
                        -ms-transform: rotate(90deg); /* IE 9 */
                        -webkit-transform: rotate(90deg); /* Safari and Chrome */
                    }
                }
            }
        }
    }

</style>
