<template>
    <div class="owner-situ">
        <el-form :model="tpData" label-width="190px" ref="form">
            <el-form-item>
                <span slot="label">
                    登记单元号
                </span>
                <span class="value-span">{{tpData.djdyh}}</span>
            </el-form-item>
            <el-form-item>
                <span slot="label">
                    登记单元名称
                </span>
                <span class="value-span" v-html="tpData.djdymc"></span>
            </el-form-item>
            <el-form-item class="lh30">
                <span slot="label">空间范围<br/>（坐落、四至描述）</span>
                <span class="value-span">{{tpData.zl}}</span>
            </el-form-item>
            <el-row>
                <el-col :span="11">
                    <el-form-item class="form-item-modify">
                        <span slot="label">
                            登记单元总面积(公顷)
                        </span>
                        <span class="value-span">{{tpData.djdyzmj}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="2">
                    <div class="in">
                        <span>其中</span>
                    </div>
                </el-col>
                <el-col :span="11">
                   <el-form-item class="columns">
                        <span slot="label">国有面积</span>
                        <span>{{tpData.gymj}}</span>
                    </el-form-item>
                    <el-form-item class="columns">
                        <span slot="label">集体所有面积</span>
                        <span>{{tpData.jtsymj}}</span>
                    </el-form-item>
                    <el-form-item class="columns">
                        <span slot="label">争议区面积</span>
                        <span>{{tpData.zyqmj}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- <el-row>
                <el-col :span="8">

                </el-col>
                <el-col :span="8">

                </el-col>
                <el-col :span="8">

                </el-col>
            </el-row> -->
            <el-form-item>
                <span slot="label">
                    所有权人
                </span>
                <span class="value-span" v-html="tpData.syqr"></span>
            </el-form-item>
            <el-form-item>
                <span slot="label">
                    所有者职责履行主体
                </span>
                <span class="value-span" v-html="tpData.dbxszt"></span>
            </el-form-item>
            <el-form-item>
                <span slot="label">
                    权利行使方式
                </span>
                <span class="value-span" v-if="tpData.qlxzfs == '1'">直接履行</span>
                <span class="value-span" v-else-if="tpData.qlxzfs == '2'">代理履行</span>
                <span class="value-span" v-else v-html="tpData.qlxzfs"></span>
            </el-form-item>
            <el-form-item v-if="tpData.qlxzfs==='2'">
                <span slot="label" v-if="tpData.lxztlx ==='1'">
                    所有者职责履行主体
                </span>
                <span slot="label" v-else-if="tpData.lxztlx ==='2'">
                    所有者职责代理履行主体
                </span>
                <span slot="label" v-else>
                    未知
                </span>
                <span class="value-span" v-html="tpData.dlxzzt"></span>
            </el-form-item>
            <el-form-item v-if="tpData.qlxzfs==='2'">
                <span slot="label">
                    代理履行的所有者职责内容
                </span>
                <span class="value-span" v-html="tpData.dlxsnr"></span>
            </el-form-item>
            <el-form-item v-if="tpData.qlxzfs=='1'">
                <span slot="label">行使内容</span>
                <span class="value-span" v-html="tpData.xsnr"></span>
            </el-form-item>
            <el-form-item>
                <span slot="label">
                    登记类型
                </span>
                <span class="value-span" v-html="tpData.djlx"></span>
            </el-form-item>
            <el-form-item>
                <span slot="label">登记时间</span>
                <span class="value-span">{{tpData.djsj}}</span>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item>
                        <span slot="label">审核人</span>
                        <span>{{tpData.shrr }}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item>
                        <span slot="label">登簿人</span>
                        <span>{{tpData.dbr }}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item>
                <span slot="label">
                    附记
                </span>
                <span class="value-span">{{tpData.fj }}</span>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    export default {
        filters: {
            defaultValue(val) {
                if (val == '' || val == undefined) {
                    return '-'
                }
                return val
            }
        },
        data() {
            return {
                tpData: {},
            }
        },
        methods: {},
        props: ['ownerSituDetails'],
        mounted() {
            this.tpData = this.ownerSituDetails;
        }
    }

</script>
<style lang="scss">
    .owner-situ {
        .el-form {
            border: 1px solid #d9d9d9;
            border-bottom: none;

            .el-form-item {
                margin-bottom: 0;
                border-bottom: 1px solid #D9D9D9;
                color: #666;

                .el-form-item__label {
                    font-size: 18px;
                    text-align: center;
                    line-height: 50px;
                    background-color: #f2f2f2;
                    border-right: 1px solid #D9D9D9;
                    padding-right:0;

                    .el-icon-question {
                        font-size: 16px;
                        color: #F39920;
                        cursor: pointer;
                    }
                }

                .el-form-item__content {
                    font-size: 16px;
                    line-height: 50px;

                    span {
                        padding-left: 10px;
                    }
                }

            }
            .form-item-modify{
                .el-form-item__label,.el-form-item__content {
                    line-height:152px;
                }
            }
            .lh30{
                .el-form-item__label{
                    line-height:30px;
                    padding:5px 0;
                    min-height: 260px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .value-span{
                    display: inline-block;
                }
                span{
                    white-space:pre-line;
                }
            }
            .in{
                line-height:152px;
                border:1px solid #D9D9D9;
                border-top:none;
                text-align:center;
                font-size:18px;
                color:#606266;
            }

            .columns > .el-form-item__content {
                margin-left: 0 !important;
                padding-left: 0;
            }

            .columns {
                .el-form-item__label{
                    background-color:transparent;
                }
                .el-form-item__content {
                    .value-span {
                        display: none;
                    }
                }

                .el-row {
                    .el-col:nth-child(2), .el-col:nth-child(3) {
                        border-left: 1px solid #d9d9d9;
                    }

                    .el-form-item {
                        border-bottom: none;
                    }
                }
            }
        }
    }

</style>
