<template>
    <div class="certificate">
        <el-carousel :autoplay="false" class="cert" trigger="click">
            <el-carousel-item>
                <div>
                    <img src="../../../../assets/images/cert_1.jpg">
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <div class="rl">
                    <img src="../../../../assets/images/cert_2.jpg">
                    <!--<div class="qr" ref="qr"></div>-->
                    <vue-qr :text="url +qsData.djdyh" class="qr"></vue-qr>
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <div class="bg">
                    <div class="cert-info">
                        <p class="title"><span style="text-decoration:underline">浙</span>(<span
                                class="t">2020</span>)<span style="text-decoration:underline">开化县</span> 自然资源所有权第<span
                                class="t" style="text-decoration:underline">0000001</span>号</p>
                        <table class="info-table">
                            <tr>
                                <td width="128">登记单元名称</td>
                                <td colspan="3">{{qsData.djdymc}}</td>
                            </tr>
                            <tr>
                                <td>登记单元号码</td>
                                <td colspan="3">{{qsData.djdyh}}</td>
                            </tr>
                            <tr>
                                <td>登记单元类型</td>
                                <td colspan="3">{{qsData.djdylx}}</td>
                            </tr>
                            <tr>
                                <td>空间范围<br/>(坐落、四至描述)</td>
                                <td colspan="3">{{qsData.zl}}</td>
                            </tr>
                            <tr>
                                <td>登记单元总面积<br/>(公顷)</td>
                                <td width="100">{{qsData.djdyzmj}}</td>
                                <td width="80">国有<br/>面积</td>
                                <td>{{qsData.gymj}}</td>
                            </tr>
                            <tr>
                                <td>所有权人</td>
                                <td>{{qsData.syqr}}</td>
                                <td>代表行使<br/>主体</td>
                                <td>{{qsData.dbxszt}}</td>
                            </tr>
                            <tr>
                                <td rowspan="3">权利行使方式</td>
                                <td>直接行使({{qsData.qlxzfs=='1'?'√':''}})</td>
                                <td>行使内容</td>
                                <td>{{qsData.xsnr}}</td>
                            </tr>
                            <tr>
                                <td rowspan="2">代理行使({{qsData.qlxzfs==='2'?'√':''}})</td>
                                <td>代理行使<br/>主体</td>
                                <td>{{qsData.dlxzzt}}</td>
                            </tr>
                            <tr>
                                <td>代理行使<br/>内容</td>
                                <td>{{qsData.dlxsnr}}</td>
                            </tr>
                            <tr>
                                <td>附记</td>
                                <td colspan="3">{{qsData.fj}}</td>
                            </tr>
                        </table>
                        <p class="sign">
                            <span class="agency">登记机构 （章）</span><br/>
                            <span class="date">
                                {{qsData.djsj}}
                            </span>
                            <img class="seal" src="../../../../assets/images/seal.png"/>
                        </p>
                        <p class="number">
                            编号 No <span>Z00123456789</span>
                        </p>
                    </div>
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <div class="bg">
                    <p class="title">附图</p>
                    <div class="cert-attach">
                        <img :key="img.ydlj" :src="img.ydlj" v-for="img in txData">
                    </div>
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <div>
                    <img src="../../../../assets/images/cert_5.jpg">
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
    import urlConfig from "../../../../utils/urlConfig";
    import vueQr from 'vue-qr'

    export default {
        name: 'certificate',
        props: ['ownerSituDetails', 'relatedInfoTp'],
        components: {
            vueQr
        },
        mounted() {

        },
        watch: {
            'ownerSituDetails': {
                handler(val) {
                    if (val != undefined) {
                        this.qsData = this.ownerSituDetails;
                        // this.createQRCOde()
                    }
                },
                immediate: true,
            },
            'relatedInfoTp': {
                handler(val) {
                    if (val != undefined) {
                        this.txData = this.relatedInfoTp;
                    }
                },
                immediate: true,
            }
        },
        methods: {

        },
        data() {
            return {
                qsData: {},
                txData: [],
                url: urlConfig.wxappUrl,
            }
        },

    }
</script>

<style lang="scss">
    .certificate {
        width: 600px;
        margin: 0 auto;

        .cert {
            .el-carousel__container {
                height: 880px;

                .el-carousel__arrow {
                    font-size: 46px;
                    width: 60px;
                    height: 60px;
                }

                img {
                    width: 100%;
                }

                .qr {
                    width: 140px;
                    position: absolute;
                    left: calc(50% - 70px);
                    top: 50%;
                    z-index: 99;
                }
            }

            .bg {
                padding: 88px;
                height: 704px;
                background: url(../../../../assets/images/cert_bg.jpg) center no-repeat;

                .title {
                    font-size: 16px;
                    text-align: center;

                    .t {
                        display: inline-block;
                        width: 70px;
                        text-align: center;
                    }
                }

                .cert-info {
                    .info-table {
                        width: 100%;
                        margin: 30px 0 25px;
                        border-collapse: collapse;

                        td {
                            font-family: '楷体';
                            line-height: 30px;
                            font-size: 16px;
                            text-align: center;
                            border: 1px solid #1a1a1a;
                        }
                    }

                    .sign {
                        font-size: 16px;
                        text-align: right;
                        line-height: 30px;
                        position: relative;

                        .date {
                            .year, .month, .day {
                                display: inline-block;
                                width: 10px;
                                text-align: center;
                            }
                        }

                        .seal {
                            position: absolute;
                            width: 114px;
                            height: 114px;
                            right: 0;
                            top: -20px;
                        }
                    }

                    .number {
                        font-size: 16px;
                        text-align: center;
                        margin-top: 15px;
                    }
                }

                .cert-attach {
                    height: 80%;
                    margin-top: 10px;
                    border: 1px solid #1a1a1a;
                }
            }

            .bg.cover {
                background-image: url(../../../../assets/images/cert_1.jpg)
            }

        }

        .cert.el-carousel--horizontal {
            overflow-y: hidden;
        }
    }
</style>
