<template>
  <div class="info-detail center">

    <bread-crumb v-if="path !=='/unit-model-detail'"></bread-crumb>
    <!-- 走马灯及信息展示区域 -->
    <el-row :gutter="20" class="info-row">
      <el-col :span="11">
        <div class="swiper-container gallery">
          <div class="swiper-wrapper swiper-no-swiping">
            <div v-for="item in dyTp" :key="item.id" class="swiper-slide">
              <img v-if="item.lx=='image'" :src="item.ydlj" preview="1"/>
              <video v-if="item.lx=='video'" :src="item.ydlj" controls="controls"
                     preload="meta"
                     style="width: 100%;height: 100%;cursor: pointer;"/>
            </div>
          </div>
        </div>
        <div class="thumbs-box">
          <div class="swiper-container thumbs">
            <div class="swiper-wrapper">
              <div v-for="item in dyTp" :key="item.id" class="swiper-slide">
                <div class="slide">
                  <img v-if="item.lx=='image'" :src="item.ydlj"/>
                  <img v-if="item.lx=='video'" :src="item.jtlj"/>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </el-col>
      <el-col :span="13" class="info-col">
        <el-row class="title-row">
          <el-col :span="16">
            <p class="title">{{ tpData.djdymc }}</p>
          </el-col>
          <el-col :span="8" class="follow-col">
            <span @click="handleFollow">
                <i :class="[infoData.isFollow?'icon-follow': 'icon-unfollow']" class="icon"></i>
                {{ infoData.followText }}
            </span>
          </el-col>
        </el-row>
        <el-row class="value-row">
          <el-col :span="12" class="col-zylx">
            <div class="con">
              <span class="value c1">{{ tpData.zylx }}</span><br/>
              <span class="key">资源类型</span>
            </div>
          </el-col>
          <el-col :span="12" class="col-djlx">
            <div class="con">
              <span
                  class="value c4">{{ tpData.djlx }}</span><br/>
              <span class="key">登记类型</span>
            </div>
          </el-col>
          <el-col :span="12" class="col-djjg">
            <div class="con">
              <span
                  class="value c2">{{ tpData.djjg }}</span><br/>
              <span class="key">登记机构</span>
            </div>
          </el-col>
          <el-col :span="12" class="col-lxzt">
            <div class="con">
              <span class="value c3">{{ tpData.syzzzdllxzt }}</span>
              <br/>
              <span v-if="tpData.lxztlx === '1'" class="key">所有者职责履行主体</span>
              <span v-else-if="tpData.lxztlx === '2'" class="key">所有者职责代理履行主体</span>
              <span v-else class="key">未知</span>
            </div>
          </el-col>
        </el-row>
        <!--        <el-row class="reg-row">-->
        <!--          <el-col :span="12">-->
        <!--            <span class="num marl">登记单元号：{{ tpData.djdyh }}</span>-->

        <!--          </el-col>-->
        <!--          <el-col :span="12">-->
        <!--            <span class="mj marl">登记总面积：{{ tpData.mj }}公顷</span>-->
        <!--          </el-col>-->
        <!--          <el-col>-->
        <!--            <span class="time marl">登记时间：{{ tpData.djsj }}</span>-->
        <!--          </el-col>-->
        <!--          <el-col :span="12">-->
        <!--            <span class="location marl">地理位置：{{ tpData.dqwz }}</span>-->
        <!--          </el-col>-->
        <!--          <el-col :span="12" class="look-map">-->
        <!--            <el-button size="mini" @click="mapVisible = true">查看地图</el-button>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <el-row class="btn-row">
          <el-col>
            <share :config="share_config" class="fx"></share>
            <!--                TODO 暂时不展示此功能-->
            <!-- <el-button @click="loginState" icon="icon-sug" type="danger"
                       v-if="$store.state.homeSystem.kqtsjy">投诉建议
            </el-button> -->
            <!--            <el-button class="lyBtn" icon="el-icon-place" size="mini" @click="mapVisible = true">查看地图</el-button>-->

            <el-button class="lyBtn" v-if="$store.state.homeSystem.kqtsjy" icon="icon-sug"
                       @click="loginState">我要留言
            </el-button>
            <el-button icon="el-icon-camera" type="primary" @click="vrDialog = true">全景
            </el-button>
            <!--                        <el-button icon="icon-vir" type="primary">虚拟旅游</el-button>-->
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="info-menu">
      <el-col :span="4">
        <div class="menu">
          <div class="trigger register">
            登记成果
          </div>
        </div>
      </el-col>
      <el-col :span="20">
        <div class="dj-name" @click="goToDjcg(tpData.djcgdz,tpData.fjdz)"    >
          {{ tpData.djcgmc }}
        </div>
      </el-col>
    </el-row>
    <el-row class="unit-iframe-box">
      <iframe @load="frm" id="pdf-box" frameborder='0' :src="tpData.fjdz" v-if="tpData.fjdz"></iframe>
    </el-row>

    <!-- 资源、权属等情况tabs标签区域 - 修改 -->
    <el-row  class="info-menu">
      <!--      <el-col :span="8">-->
      <!--        <div class="menu">-->
      <!--          <div slot="reference" :class="{'active': active == 'cert'}" class="trigger cert"-->
      <!--               @click="showCertificate('cert')">证书-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </el-col>-->
      <el-col :span="12">
        <div class="menu">
          <el-dropdown placement="bottom" trigger="click" @command="handleKnowledgeMenu">
            <div class="trigger knowledge"
                 @click="showKnowledge('knowledge')">科普知识
            </div>
            <el-dropdown-menu slot="dropdown" class="menu-popper"
                              v-if="infoMenu.knowledge && infoMenu.knowledge.length>1">
              <div class="menu-list">
                <el-dropdown-item v-for="item in infoMenu.knowledge" :key="item.value" :command="item">
                  {{ item.label }}
                </el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <el-dropdown @command="kpzsMenu" placement="bottom" trigger="click">

               <el-dropdown-menu class="menu-popper" slot="dropdown">
                   <div class="menu-list">
                       <el-dropdown-item :command="item" :key="item.label" v-for="item in infoMenu.knowledge">
                           {{item.label}}
                       </el-dropdown-item>
                   </div>
               </el-dropdown-menu>
           </el-dropdown> -->
        </div>
      </el-col>
    </el-row>

    <div v-if="showFixBar" class="tempBar"></div>


    <el-row
        v-if="activeName != 'register'&&activeName != 'resource'&&activeName != 'history'&&activeName != 'natural'&&activeName != 'travel'"
        class="menu-title">
      <el-col class="title">
        <span>{{ currentMenu }}</span>
      </el-col>
    </el-row>

    <!-- 资源、权属等情况tabs标签区域 -->
    <div class="info-cont">
      <div v-for="(item, index) of infoMenu.knowledge" :key="index">
        <component :is="item.value" v-if="item.value!=='resource'&&currentMenu === item.label"
                   :key="index"
                   :tabId="item.name" :wbdz="item.wbdz"
                   :zrzyId="zrzyId">
        </component>
      </div>
      <register v-if="activeName === 'register'" :djdymc="tpData.djdymc" :qsxx="qszk"></register>
      <!-- 新增科普知识入口菜单 -->
      <popular-science-knowledge-card
          v-if="activeName === 'resource'"
          :knowledgeCurrentMenu="knowledgeCurrentMenu"
          :menulist="infoMenu.knowledge"
          :renderComponent="renderComponent"
              :model-name="modelName"
              :menu-item="menuItem">
      </popular-science-knowledge-card>
      <!--      <kpzs-menu v-if="activeName === 'resource'" :menulist="infoMenu.knowledge"></kpzs-menu>-->
      <!-- <resource-situ :gs="tpData.gs" v-if="activeName === 'resource'"></resource-situ> -->
      <owner-situ v-if="activeName === 'owner'" :ownerSituDetails="DetailsData.qszk"></owner-situ>
      <nature-situ v-if="activeName === 'natureSitu'" :natureSituDetails="DetailsData"></nature-situ>
      <related-info v-if="activeName === 'related'" :relatedInfoDetails="DetailsData"></related-info>
      <graph-info v-if="activeName === 'graph'" :relatedInfoTp="DetailsTP"></graph-info>
      <tour-expe v-if="activeName === 'tour'"></tour-expe>
      <certificate v-if="activeName === 'certificate'" :ownerSituDetails="DetailsData.qszk"
                   :relatedInfoTp="DetailsTP"></certificate>

      <!-- 右侧二级菜单锚点 -->
      <div v-if="infoMenu.knowledge.length>1" :class="{'fixed': isFixed}" class="steps">
        <ul class="fr">
          <li class="end"><span></span></li>
          <li v-for="i in infoMenu.knowledge.length" :key="i">
            <span></span>
          </li>
          <li class="end"><span></span></li>
        </ul>
        <ul class="anchor">
          <li v-for="(item, index) in infoMenu.knowledge" :key="index" :class="{'active': currentMenu == item.label}"
              @click="jump(item, index)">
            <span class="dot fl"></span>
            <span class="text">{{ item.label }}</span>
          </li>
        </ul>
      </div>

    </div>

    <!-- 地图区域弹窗 -->
    <el-dialog :visible.sync="mapVisible" custom-class="map-dialog" title="地理位置" top="5%" width="70%">
      <el-row class="info-map">
        <el-col class="map">
          <tian-map :parkPlace="tpData"></tian-map>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- 投诉建议窗口 -->
    <el-dialog
        :title="tpData.djdymc+'--投诉建议'"
        :visible.sync="centerDialogVisible"
        center
        custom-class="sugg-dialog"
        destroy-on-close
        top="10vh"
        width="800px"
        @opened="setFormData">
      <tsjy ref="tsjy" @closeVisible="closeDialog"></tsjy>
      <!--            <span class="dialog-footer" slot="footer">-->
      <!--                <el-button @click="centerDialogVisible = false">取 消</el-button>-->
      <!--                <el-button @click="saveRule" type="primary">确 定</el-button>-->
      <!--            </span>-->
    </el-dialog>
    <el-dialog :close-on-press-escape="true" :fullscreen="true" :visible.sync="vrDialog" destroy-on-close
               @opened="setVrData">
      <vr ref="vr"></vr>
    </el-dialog>
  </div>
</template>
<script>
import breadCrumb from '@/components/common/breadCrumb'
import resourceSitu from './components/resourceSitu'
import ownerSitu from './components/ownerSitu'
import natureSitu from './components/natureSitu'
import relatedInfo from './components/relatedInfo'
import graphInfo from './components/graphInfo'
import natureEnv from './components/natureEnv'
import historyHum from './components/historyHum'
import tourExpe from './components/tourExpe'
import certificate from './components/certificate'
import register from './components/register'
import kpzsMenu from './components/kpzsMenu'
import PopularScienceKnowledgeCard from './components/popular-science-knowledge-card'

import Swiper from 'swiper'
import preview from '@/plugins/preview'
// import commentChangyan from "../../../plugins/changyan/commentChangyan";
import tianMap from "../../../components/tianMap";

import {getDyDetailsInfo, getDyOneInfoByDyId} from "../../../api/dyInfoService";
import {getDyTab, getGraphImagesByClass} from "../../../api/homeService";
import {checkToken} from "../../../api/systemService";
import CustomPage from "./components/customPage";
import Tsjy from "../../../components/tsjy";
import Vr from "../../../components/vr";
import {checkCollection, getCollectionState, tsjyLoginState} from "../../../api/personService";
import storage from "../../../utils/storage";
import {isNull} from "../../../utils/utils-function";

export default {
  components: {
    Vr,
    preview,
    Tsjy,
    CustomPage,
    breadCrumb,
    resourceSitu,
    ownerSitu,
    natureSitu,
    relatedInfo,
    graphInfo,
    natureEnv,
    historyHum,
    tourExpe,
    // commentChangyan,
    tianMap,
    certificate,
    register,
    kpzsMenu,
    PopularScienceKnowledgeCard
  },
  data() {
    return {
      vrDialog: false,
      zrzyId: '',
      path: '', // 当前路由
      /**
       * qszk用于临时记录DetailsData中的qszk（权属状况）
       * 如果直接使用DetailsData.qszk.djdyh，会导致左侧缩略图无法正常使用！
       */
      qszk: {},
      tpData: {}, //单元信息
      dyTp: [], //轮播图
      DetailsData: {}, //单元详情
      DetailsTP: {}, // 图形信息
      /**
       * share的配置
       */
      share_config: {
        sites: ['weibo', 'qq', 'wechat', 'qzone'], // 启用的站点
        url: window.location.href
      },
      //是否关注
      infoData: {
        followText: '关注',
        isFollow: false,
      },
      currentMenu: '资源概况',
      activeName: 'knowledge',
      centerDialogVisible: false, // 投诉建议对话框
      dialogImageUrl: '',
      dialogVisible: false,
      infoMenu: {
        register: [
          {
            value: 'register',
            label: '封面'
          },
          {
            value: 'owner',
            label: '权属概况'
          },
          {
            value: 'natureSitu',
            label: '自然状况'
          },
          {
            value: 'related',
            label: '关联信息'
          },
          {
            value: 'graph',
            label: '图形信息'
          }
        ],
        knowledge: [
          {
            value: 'resource',
            label: '资源概况',
            src: require("../../../assets/images/kpzs/pic-zygk.png"),
            link: "/info/infoDetail/zygkInfor",
            zrzyDyId: this.zrzyId,
            tabId: ""
          },
        ]
      },
      mapVisible: false, //地图弹窗
      active: 'knowledge',
      isFixed: false, // 右侧锚点样式判断
      stepsVisible: false, // 右侧锚点是否显示
      anchors: [],// 右侧锚点列表
      showFixBar: false,
      knowledgeCurrentMenu: '资源概况',
      menuItem: {},
      renderComponent:false,
      modelName:'',
    }
  },
  created() {
    this.currentActive('knowledge');
    this.zrzyId = this.$route.query.zrzyDyId;
    this.path = this.$route.path;
    this.getDyClassify(this.zrzyId);
    this.getImg(this.zrzyId);
    /**
     * 将zylx转换为mc
     */
    getDyDetailsInfo(this.zrzyId).then(response => {
      console.log(response.data)
      this.DetailsData = {...response.data};
      this.qszk = this.DetailsData.qszk;   //qzsk用于临时保存权属状况（为了获取登记单元号），理由详见data()中的定义处
    })
  },
  mounted() {
    // this.zrzyId = this.$route.query.zrzyDyId;
    // this.getDyClassify(this.zrzyId);
    // this.getImg(this.zrzyId);
    // /**
    //  * 将zylx转换为mc
    //  */
    // getDyDetailsInfo(this.zrzyId).then(response => {
    //   this.DetailsData = {...response.data};
    //   this.qszk = this.DetailsData.qszk;   //qzsk用于临时保存权属状况（为了获取登记单元号），理由详见data()中的定义处
    // })

    getDyOneInfoByDyId(this.zrzyId, null).then(response => {
      this.tpData = response.data;
      console.log(this.tpData)
      document.title = isNull(this.tpData.djdymc) ? '单元详情' : this.tpData.djdymc;
    })


    this.getDyLbt(this.zrzyId);
    // 给页面注册 scroll 事件监听
    this.$nextTick(function () {
      window.addEventListener('scroll', this.onScroll)
    })
    this.getFollow();
  },


  /**
   *
   * @type {{getImg(any=): void, getDyLbt(any=): void, getDyClassify(any=): void, handlePictureCardPreview(any): void, handleFollow(): void, saveRule(): (null | undefined), handleRemove(any=, any=): void, handleInfoMenu(any): void, showCertificate(): void, currentActive(any=): void, closeDialog(any): void, ...}}
   */
  methods: {
    loginState() {
      tsjyLoginState().then(res => {
        this.centerDialogVisible = true
        this.$router.push({
          path: "/tsjycon?dyId=" + this.tpData.id + '&dymc=' + this.tpData.djdymc,
        });
      })
    },
    /*  */

    goToDjcg(url,fjdz) {
      if (url && isNull(fjdz)) {
        window.open(url);
      }
    },
    frm(){
      let iframe = document.getElementById("pdf-box");
      let obj = iframe.contentWindow;
      let img = obj?.document.getElementsByTagName('img')[0]
      if(img){
        img.width = iframe.clientWidth;
      }
    },
    //是否关注
    handleFollow() {
      const param = {graphId: this.zrzyId, type: 'follow'};
      checkCollection(param).then(res => {
        if (res.data !== null && res.data === '1') {
          this.infoData.isFollow = true;
          this.infoData.followText = "已关注";
        } else if (res.data !== null && res.data === '0') {
          this.infoData.isFollow = false;
          this.infoData.followText = "关注";
        } else {
          this.$message({
            message: res.data,
            type: 'warning'
          })
        }
      })
    },
    //上传附件
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //获取自定义科普菜单
    getDyClassify(zrzyId) {
      this.infoMenu.knowledge[0].link = "/info/infoDetail/zygkInfor?zrzyDyId=" + this.zrzyId
      getDyTab(zrzyId).then(res => {
        if (res.code === '0') {
          for (let i = 0; i < res.data.length; i++) {
            const data = {
              label: res.data[i].mc,
              name: res.data[i].id,
              link: res.data[i].pclj + '?zrzyDyId=' + this.zrzyId + '&tabId=' + res.data[i].id,
              wbdz: res.data[i].wbdz,
              src: res.data[i].pctb,
              zrzyDyId: this.zrzyId,
              tabId: res.data[i].id,
              mklx:res.data[i].mklx,
            }
            this.infoMenu.knowledge.push(data)
          }
        }
      })
    },
    /**
     * 获取登记单元图
     * @param zrzyId
     */
    getImg(zrzyId) {
      const tw = {'twmbId': zrzyId, 'twlx': 'dy', 'twFqlx': '2'}
      getGraphImagesByClass(tw).then(res => {
        this.DetailsTP = res.data;
      })
    },
    /**
     * 获取轮播图片
     * @param zrzyId
     */
    getDyLbt(zrzyId) {
      const tw = {'twmbId': zrzyId, 'twlx': 'dy', 'twFqlxList': ['lbt', '1']}
      getGraphImagesByClass(tw).then(res => {

        if (res.code === '0') {
          this.dyTp = res.data;
          let thumbsSwiper = new Swiper('.thumbs', {
            // spaceBetween: 6,
            slidesPerView: 5,
            watchSlidesVisibility: true,/*避免出现bug*/
            slideThumbActiveClass: '',
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            observer: true,             //解决bug使用
            observerParents: false,
            onSlideChangeEnd: (swiper) => {
              swiper.update();
              mySwiper.startAutoplay();
              mySwiper.reLoop();
            }
          });

          let mySwiper = new Swiper('.gallery', {
            thumbs: {
              swiper: thumbsSwiper
            },
            slidesPerView: 1,
            // spaceBetween: 10
            observer: true,             //解决bug使用
            observerParents: false,
            onSlideChangeEnd: (swiper) => {
              swiper.update();
              mySwiper.startAutoplay();
              mySwiper.reLoop();
            }
          });
          // 异步获取图片时，需要刷新重置，否则不生效
          this.$previewRefresh()
        }
      })

    },

    /**
     * 处理tabs菜单
     */
    handleInfoMenu(item) {
      this.activeName = item.value
      this.currentMenu = item.label
      this.stepsVisible = true
    },
    /**
     * 科普知识下拉菜单切换
     */
    handleKnowledgeMenu(item) {
      //this.knowledgeCurrentMenu = item.label;
      this.knowledgeCurrentMenu = item.mklx;
       this.currentMenu = item.label;
       this.menuItem = item;
       this.modelName = item.label;
       this.$nextTick(() => {
         this.renderComponent = true;
       });
    },

    kpzsMenu(item) {

    },
    //证书展示
    showCertificate() {
      this.activeName = 'certificate'
      this.currentMenu = '证书'
      this.active = 'cert'
      this.stepsVisible = false
    },
    showKnowledge() {
      this.activeName = 'resource'
      // this.currentMenu = '证书'
      this.active = 'knowledge'
      this.stepsVisible = false
      console.log(this.DetailsData)

    },
    // 每个tab选中状态
    currentActive(value) {
      if (value === 'knowledge') {
        this.stepsVisible = false
        this.activeName = "resource"
        return
      }
      if (value !== 'register') {
        //默认显示第一项
        this.activeName = this.infoMenu[value][0].value
        this.currentMenu = this.infoMenu[value][0].label
      } else {
        this.activeName = value
        this.currentMenu = '封面'
      }
      this.anchors = this.infoMenu[value]
      this.active = value
      this.stepsVisible = true
      console.log(this.DetailsData)
    },
    // 锚点跳转
    jump(item) {
      console.log(item)
      // this.knowledgeCurrentMenu = item.label;
      this.knowledgeCurrentMenu = item.mklx;
      this.currentMenu = item.label;
      this.menuItem = item;
      this.modelName = item.label;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },

    // 鼠标滚动事件方法
    onScroll() {
      let scrolled = document.documentElement.scrollTop || document.body.scrollTop
      // 823 为menu-title的offsetTop值
      this.isFixed = scrolled >= 820;

      const path = this.$route.path;
      if (path === '/info/infoDetail') {
        if (scrolled >= 726) {
          this.showFixBar = true;
          document.getElementById('navBar')?.classList.add('fixedBar');
        } else {
          this.showFixBar = false;
          document.getElementById('navBar')?.classList.remove('fixedBar');
        }
      }
    },
    //保存投诉信息
    // saveRule() {
    //     let ts = this.$refs.tsjy.getTs();
    //     if (ts == null) {
    //         return null
    //     }
    // },
    //关闭弹窗
    closeDialog(data) {
      this.centerDialogVisible = data
    },
    //赋值
    setFormData() {
      this.$refs.tsjy.setFormData(this.zrzyId);
    },
    //赋值
    setVrData() {
      this.$refs.vr.setData(this.zrzyId);
    },
    /**
     * 获取收藏状态
     */
    getFollow() {
      const user = storage.get('user');
      if (user !== null && user != '') {
        checkToken({"token": user.access_token}).then(res => {
          if (res.status == 200) {
            const id = this.zrzyId;
            const param = {graphId: id, type: 'follow'};
            getCollectionState(param).then(res => {
              if (res.data !== null) {
                this.infoData.isFollow = true;
                this.infoData.followText = "已关注";
              } else {
                this.infoData.isFollow = false;
                this.infoData.followText = "关注";
              }
            })
          } else {
            storage.remove("user");
          }
        });

      }
    },


  },
}
</script>

<style lang="scss">
@import '~swiper/css/swiper.css';

.info-detail {
  padding-bottom: 50px;

  .info-row {
    margin-top: 10px;

    .swiper-container {
      .swiper-slide {
        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }

    .gallery {
      height: 400px;
    }

    .thumbs-box {
      position: relative;
      padding: 0 33px;
      margin-top: 10px;

      .thumbs {
        .swiper-slide {
          .slide {
            height: 50px;
            padding: 3px;
            cursor: pointer;
            border: 2px solid transparent;
          }
        }

        .swiper-slide-thumb-active {
          .slide {
            border-color: #039BE5;
          }
        }
      }

      .swiper-button-prev:after, .swiper-button-next:after {
        content: '';
      }

      .swiper-button-prev, .swiper-button-next {
        width: 20px;
        height: 60px;
        margin-top: -30px;
        background: center no-repeat;
      }

      .swiper-button-prev {
        left: 0;
        background-image: url(../../../assets/images/icon_prev.png);
      }

      .swiper-button-prev.swiper-button-disabled {
        opacity: 1;
        background-image: url(../../../assets/images/icon_prev_dis.png);
      }

      .swiper-button-next {
        right: 0;
        background-image: url(../../../assets/images/icon_next.png);
      }

      .swiper-button-next.swiper-button-disabled {
        opacity: 1;
        background-image: url(../../../assets/images/icon_next_dis.png);
      }
    }

    .info-col {
      .title-row {
        padding: 21px 0 21px;
      }

      .el-row {
        border-bottom: 1px dashed #039BE5;

        .title {
          height: 66px;
          max-height: 88px;
          overflow: hidden;
          color: #039BE5;
          font-size: 36px;
          line-height: 44px;
          vertical-align: middle;
          display: table-cell;
        }

        .follow-col {
          text-align: right;
          position: relative;
          bottom: -30px;

          span {
            display: inline-block;
            line-height: 24px;
            margin-left: 15px;
            font-size: 18px;
            cursor: pointer;

            .icon {
              display: inline-block;
              vertical-align: -6px;
              background: center no-repeat;
              width: 24px;
              height: 24px;
              margin-right: 3px;
            }

            .icon-follow {
              background-image: url(../../../assets/images/icon_like.png);
            }

            .icon-unfollow {
              background-image: url(../../../assets/images/icon_dislike.png);
            }

            .icon-forward {
              background-image: url(../../../assets/images/icon_forward.png);
            }
          }
        }

        .value {
          font-size: 24px;
        }

        .c1 {
          color: #018E33;
        }

        .c2 {
          color: #B262E5;
        }

        .c3 {
          color: rgb(246, 7, 7);
        }

        .c4 {
          color: rgba(255, 169, 0, 1);
        }

        .key {
          font-size: 16px;
          color: #666;
        }


      }

      .value-row {
        border-bottom-color: #D9D9D9;

        .con {
          text-align: center;
          line-height: 30px;
          padding: 30px 0;
          margin: 10px 0;
          border-right: 1px dashed #D9D9D9;
        }

        .el-col {
          border-bottom: 1px dashed #D9D9D9;
        }

        .col-djlx {
          .con {
            border-right: none;
            padding: 30px 0;
            margin: 10px 0;
          }
        }

        .el-col:last-child {
          .con {
            border-right: none;
          }
        }
      }

      .reg-row {
        padding: 15px 0 20px;

        .el-col {
          font-size: 16px;
          color: #666;
          line-height: 36px;
          padding-left: 10px;

          .marl {
            padding-left: 25px;
            background: left center no-repeat;
          }

          .num {
            background-image: url(../../../assets/images/icon_reg_num.png)
          }

          .mj {
            background-image: url(../../../assets/images/icon_djzmj.png)
          }

          .time {
            background-image: url(../../../assets/images/icon_reg_time.png)
          }

          .location {
            background-image: url(../../../assets/images/icon_reg_location.png)
          }
        }

        .look-map {
          text-align: right;

          .el-button {
            margin-right: 50px;
            text-align: center;
            font-size: 16px;
            padding: 3px 14px;
          }
        }
      }

      .btn-row {
        border-bottom: none;
        padding: 20px 0;

        .el-col {
          text-align: right;

          .fx {
            left: 40px;
            top: 25px;
            text-align: left;
            position: absolute;
          }

          .el-button {
            line-height: 20px !important;

            [class^=icon-] {
              display: inline-block;
              vertical-align: middle;
              width: 24px;
              height: 24px;
              margin-right: 5px;
              background: center no-repeat;
            }

            .icon-sug {
              background-image: url(../../../assets/images/icon/icon_ly.png);

            }

            .icon-vir {
              background-image: url(../../../assets/images/icon_vir.png);
            }
          }

          .lyBtn {
            width: 128px;
            height: 47px;
            background: #FFFFFF;
            border: 1px solid #3F9EFF;
            border-radius: 4px;
            color: #3F9EFF;
          }

          .el-icon-place:before {
            font-size: 15px;
          }
        }
      }
    }
  }

  .info-menu {
    margin: 45px 0 25px;
    background-color: #039be5;

    .menu {
      padding: 10px 20px;
      margin: 0 auto;

      .trigger {
        width: 100px;
        height: 52px;
        color: #fff;
        line-height: 52px;
        padding-left: 50px;
        //margin: 0 auto;
        font-size: 16px;
        text-align: center;
        border: 4px solid #fff;
        border-radius: 10px;
        background: 28px center no-repeat #039be5;
        // background-color: #039be5;
      }

      .trigger:hover, .trigger.active {
        cursor: pointer;
        color: #606266;
        background-color: #fff;
        // background-color: #039be5;
      }

      .trigger.cert {
        background-image: url(../../../assets/images/icon_cert.png)
      }

      .trigger.cert:hover, .cert.active {
        background-image: url(../../../assets/images/icon_cert_h.png)
      }

      .trigger.register {
        background-image: url(../../../assets/images/icon_regi.png)
      }

      .trigger.register:hover, .register.active {
        background-image: url(../../../assets/images/icon_regi_h.png)
      }

      .trigger.knowledge {
        background-image: url(../../../assets/images/icon_know.png)
      }

      .trigger.knowledge:hover, .knowledge.active {
        background-image: url(../../../assets/images/icon_know_h.png)
      }

    }

    .dj-name {
      display: flex;
      align-items: center;
      font-size: 26px;
      font-weight: 550;
      color: #FFFFFF;
      height: 72px;
      cursor: pointer;
    }

    .dash {
      position: absolute;
      color: #fff;
      width: 168px;
      top: calc(50% - 4px);
      line-height: 8px;
    }

    .dash.d1 {
      left: 420px;
    }

    .dash.d2 {
      left: 617px;
    }
  }
  .unit-iframe-box{
    width: 100%;
    iframe {
      width:100%;
      height: 460px;
      background: #FFFFFF;
    }
  }

  .menu-title {
    .title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000;
      line-height: 40px;
      margin-bottom: 20px;
      border-bottom: 2px solid #039BE5;
    }
  }

  .info-cont {
    .steps {
      position: absolute;
      right: 30px;
      top: 1166px;
      z-index: 99999;
      // background-color:#fff;
      padding: 8px 18px;
      border: 1px solid transparent;
      border-radius: 2px;

      .fr {
        width: 2px;
        background-color: #D1D1D1;

        li {
          padding: 12px 0;
          position: relative;
          left: -5px;;
          width: 12px;

          span {
            display: block;
            width: 8px;
            height: 8px;
            background-color: #D1D1D1;
            border: 2px solid #fff;
            border-radius: 6px;
          }
        }

        li.end {
          padding: 0;

          span {
            background-color: #fff;
            border: 2px solid #D1D1D1;
          }
        }
      }

      .anchor {
        padding: 12px 20px 12px 0;

        li {
          line-height: 36px;
          font-size: 16px;
          cursor: pointer;
          position: relative;

          .dot {
            visibility: hidden;
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #039BE5;
            border: 2px solid #99DDFF;
            border-radius: 7px;
            top: 11px;
            right: -26px;
          }

          .text {
            opacity: 0;
          }
        }

        li:hover, li.active {
          color: #039BE5;
        }

        li.active {
          .dot {
            visibility: visible;
          }
        }
      }
    }

    .steps:hover {
      border-color: #e8e8e8;
      background-color: rgba(255, 255, 255, 0.8);

      .anchor {
        .text {
          opacity: 1;
        }
      }
    }

    .steps.fixed {
      position: fixed;
      right: 30px;
      top: 50px;
    }
  }

  .map-dialog {
    .el-dialog__header {
      padding: 15px 20px;
      border-bottom: 1px solid #E6E6E6;
    }

    .el-dialog__body {
      padding: 5px 20px 0;

      .info-map {
        .map {
          height: 550px;
        }
      }
    }

  }


  .sugg-dialog {
    .explain {
      text-align: justify;

      P {
        font-size: 12px;
        color: rgba(102, 102, 102, 1);
        line-height: 18px;

      }
    }

    .explain-form {
      .explain-form-title {
        line-height: 24px;
        font-size: 18px;
        color: #303133;
        text-align: center;
        padding: 24px 0px;
      }

      .el-form-item {
        margin-bottom: 10px;
      }

      .mar-bot {
        margin-bottom: 22px;
      }

      .el-form-item__content {


        .el-radio-group {
          margin-left: 15px;
        }
      }

      .el-input__inner, .el-textarea__inner {
        /*border: 1px solid #ccc;*/
        /*background: #f6f6f6;*/
      }

      .el-textarea__inner {
        height: 100px;
      }
    }

    .upload-file {
      .upload-file-title {
        line-height: 24px;
        font-size: 18px;
        color: #303133;
        text-align: center;
        padding: 24px 0px;
      }

      .el-upload-list--picture-card .el-upload-list__item {
        width: 64px;
        height: 64px;
      }

      .el-upload--picture-card {
        width: 64px;
        height: 64px;
      }

      .el-icon-plus:before {
        position: relative;
        top: -35px;
      }
    }
  }
}

.menu-popper {
  padding: 0;
  background-color: #d5edf9;
  border-radius: 5px;
  border-bottom-left-radius: 88px;
  border-bottom-right-radius: 88px;

  .menu-list {
    padding: 20px 0 30px;

    li {
      text-align: center;
      line-height: 48px;
      font-size: 16px;
      width: 110px;
    }

    li:hover {
      cursor: pointer;
      background-color: #fff;
    }
  }
}

.fixedBar {
  position: fixed;
  top: -45px;
  z-index: 99999;
  width: 1200px;
  left: auto;
  right: auto
}

.tempBar {
  width: 1200px;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
}
</style>
