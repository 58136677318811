<template>
    <div class="nature-situ related-info">
        <p class="column">
            <el-popover
                    placement="bottom-start"
                    popper-class="nature-situ-pop"
                    trigger="hover"
                    v-model="popVisible">
                <ul class="pop-menu">
                    <li :key="menu.id" @click="handlePopMenu(menu.id, menu.label)" v-for="menu in popMenus"
                        v-if="menu.show">{{menu.label}}
                    </li>
                </ul>
                <img class="anchor" slot="reference" src="../../../../assets/images/icon_mark.png"/>
            </el-popover>
            <span>{{currentMenu}}</span>
        </p>
        <div class="table-con" ref="box">
            <div :style="{display:tpData.gtkjxx===undefined?'none':''}" class="table-row" ref="gtkjxx">
                <p class="title"> 国土空间规划明确的用途、生态保护红线、特殊保护规定等关联信息</p>
                <el-table
                        :data="tpData.gtkjxx"
                        border
                        style="width: 100%">
                    <el-table-column
                            align="center"
                            label="区块编号"
                            prop="qkbh">
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="面积（公顷）"
                            prop="kjmj">
                        <template slot-scope="scope">
                            <span>{{scope.row.mj}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="用途管制、特殊保护要求等内容"
                            prop="ytgznr"
                            width="290">
                        <template slot-scope="scope">
                            <span v-html="scope.row.gggznr"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="规定/设定时间"
                            prop="sdhdsj">
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="设置单位"
                            prop="szdw">
                        <template slot-scope="scope">
                            <span v-html="scope.row.szdw"></span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div :style="{display:tpData.bdcql===undefined?'none':''}" class="table-row" ref="bdcql">
                <p class="title"> 不动产权利关联信息</p>
                <el-table
                        :data="tpData.bdcql"
                        border
                        style="width: 100%">
                    <el-table-column
                            align="center"
                            label="不动产单元号"
                            prop="bdcdyh">
                        <template slot-scope="scope">
                            <span v-html="scope.row.bdcdyh"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="不动产权利类型"
                            prop="qllx">
                        <template slot-scope="scope">
                            <span v-html="scope.row.bdcqllx"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="权利人"
                            prop="qlr">
                        <template slot-scope="scope">
                            <span v-html="scope.row.qlr"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="面积"
                            prop="mj">
                        <template slot-scope="scope">
                            <span>{{scope.row.mj}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="登记时间"
                            prop="djsj">
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="登记机构"
                            prop="djjg">
                        <template slot-scope="scope">
                            <span v-html="scope.row.djjg"></span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div :style="{display:tpData.kyq===undefined?'none':''}" class="table-row" ref="kyq">
                <p class="title"> 矿业权关联信息</p>
                <el-table
                        :data="tpData.kyq"
                        border
                        style="width: 100%">
                    <el-table-column
                            align="center"
                            label="勘察/采矿许可证号"
                            prop="number">
                        <template slot-scope="scope">
                            <span v-html="scope.row.xkzh"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="探矿/采矿权人"
                            prop="holder">
                        <template slot-scope="scope">
                            <span v-html="scope.row.qlr"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="地址"
                            prop="address">
                        <template slot-scope="scope">
                            <span v-html="scope.row.dz"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="矿种"
                            prop="type">
                        <template slot-scope="scope">
                            <span v-html="scope.row.kckz"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="面积">
                        <template slot-scope="scope">
                            <span>{{scope.row.mj}}公顷</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="有效期限"
                            prop="date">
                        <template slot-scope="scope">
                            <span>{{scope.row.qlqssj}}-{{scope.row.qljssj}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="发证机关"
                            prop="agency">
                        <template slot-scope="scope">
                            <span v-html="scope.row.fzjg"></span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div :style="{display:tpData.qsq===undefined?'none':''}" class="table-row" ref="qsq">
                <p class="title"> 取水权关联信息</p>
                <el-table
                        :data="tpData.qsq"
                        border
                        style="width: 100%">
                    <el-table-column
                            align="center"
                            label="取水许可证号">
                        <template slot-scope="scope">
                            <span v-html="scope.row.xkzh"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="取水权人"
                            prop="holder">
                        <template slot-scope="scope">
                            <span v-html="scope.row.qsqr"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="取水地点"
                            prop="location">
                        <template slot-scope="scope">
                            <span v-html="scope.row.qsdd"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="取水量"
                            prop="amount">
                        <template slot-scope="scope">
                            <span v-html="scope.row.qsl"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="有效期限"
                            prop="date">
                        <template slot-scope="scope">
                            <span>{{scope.row.qlqssj}}-{{scope.row.qljssj}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="审批机关"
                            prop="agency">
                        <template slot-scope="scope">
                            <span v-html="scope.row.spjg"></span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div :style="{display:tpData.pwq===undefined?'none':''}" class="table-row" ref="pwq">
                <p class="title"> 排污权关联信息</p>
                <el-table
                        :data="tpData.pwq"
                        border
                        style="width: 100%">
                    <el-table-column
                            align="center"
                            label="排污许可证号">
                        <template slot-scope="scope">
                            <span v-html="scope.row.xkzh"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="单位名称"
                            prop="dwmc">
                        <template slot-scope="scope">
                            <span v-html="scope.row.dwmc"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="污染物种类"
                            prop="wrwzl">
                        <template slot-scope="scope">
                            <span v-html="scope.row.wrwzl"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            prop="limit">
                        <template slot="header">
                            <span>排污浓度限值</span>
                        </template>
                        <template slot-scope="scope">
                            <span v-html="scope.row.pfndxz"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="有效期限"
                            prop="date">
                        <template slot-scope="scope">
                            <span>{{scope.row.qlqssj}}-{{scope.row.qljssj}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="发证机关"
                            prop="fzjg">
                        <template slot-scope="scope">
                            <span v-html="scope.row.fzjg"></span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-row attach">
                <div class="left">
                    <span>附记</span>
                </div>
                <div class="right">
                    <el-input :disabled="true" type="textarea" v-model="tpData.gtkjxx[0].fj"></el-input>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                tpData: {},
                currentMenu: '国土空间规划明确的用途、生态保护红线、特殊保护规定等关联信息',
                popMenus: [
                    {
                        id: 'gtkjxx',
                        label: '国土空间规划明确的用途、生态保护红线、特殊保护规定等关联信息',
                        show: false,
                        top: 0,
                    },
                    {
                        id: 'bdcql',
                        label: '不动产权利关联信息',
                        show: false,
                        top: 0,
                    },
                    {
                        id: 'kyq',
                        label: '矿业权关联信息',
                        show: false,
                        top: 0,
                    },
                    {
                        id: 'qsq',
                        label: '取水关联信息',
                        show: false,
                        top: 0,
                    },
                    {
                        id: 'pwq',
                        label: '排污权关联信息',
                        show: false,
                        top: 0,
                    }
                ],
                popVisible: false,

            }
        },
        methods: {
            // 处理popMenu菜单
            handlePopMenu(id, label) {
                this.$refs.box.scrollTop = this.$refs[id].offsetTop
                this.currentMenu = label
                this.popVisible = false
            },
            tableWheel() {
                // 获取并处理 scrollTop与offsetTop
                let scrollTop = this.$refs.box.scrollTop
                // console.log(scrollTop)

                if (scrollTop === 0) {
                    this.currentMenu = this.popMenus[0].label
                } else {
                    for (let i = 0; i < this.popMenus.length; i++) {
                        if (scrollTop - this.popMenus[i].top >= 0 && this.popMenus[i].show) {
                            this.currentMenu = this.popMenus[i].label
                        } else {
                            break;
                        }
                    }
                }
            }
        },
        created() {
            this.tpData = this.relatedInfoDetails
        },
        props: ['relatedInfoDetails'],
        mounted() {
            // 添加是否显示及位置数据
            for (let i = 0; i < this.popMenus.length; i++) {
                if (this.popMenus[i].id in this.relatedInfoDetails) {
                    this.popMenus[i].show = true
                    this.popMenus[i].top = this.$refs[this.popMenus[i].id].offsetTop
                }
            }
            //设置默认第一项
            for (let i = 0; i < this.popMenus.length; i++) {
                if (this.popMenus[i].show) {
                    this.currentMenu = this.popMenus[i].label
                    break;
                }
            }
            // 监听表格box滚动事件
            var box = this.$refs.box
            // box.addEventListener('scroll', this.tableWheel)
            box.onmousewheel = this.tableWheel
        }
    }
</script>
<style lang="scss">
    .related-info {
        .table-row {
            border: none;
        }

        .attach-row {
            border: 1px solid #EBEEF5;
        }
    }

</style>
