<template>
    <div class="history-hum tour-exep">
        <el-row :gutter="12">
            <el-col :span="6" v-for="(item, index) of items" :key="index">
                <div class="box">
                    <img class="tumb" :src="item.src" />
                    <p class="text">{{item.text}}</p>
                    <p class="date">{{item.date}}</p>
                </div>
            </el-col>
        </el-row>
        <pagination></pagination>
    </div>
</template>
<script>
    import pagination from '@/components/common/pagination'
    export default {
        components: {
            pagination
        },
        data() {
            return {
                items: [
                    {
                        src: 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=952390237,2913690801&fm=15&gp=0.jpg',
                        text: '这个夏天来这里，带你看最美的湖。',
                        date: '2019年11月20日'
                    },
                    {
                        src: 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=952390237,2913690801&fm=15&gp=0.jpg',
                        text: '打着“飞的”去品尝玉树的美酒。',
                        date: '2019年11月20日'
                    },
                    {
                        src: 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=952390237,2913690801&fm=15&gp=0.jpg',
                        text: '打着“飞的”去游多情西海。',
                        date: '2019年11月20日'
                    },
                    {
                        src: 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=952390237,2913690801&fm=15&gp=0.jpg',
                        text: '打着“飞的”去看雪域高原。',
                        date: '2019年11月20日'
                    },
                    {
                        src: 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=952390237,2913690801&fm=15&gp=0.jpg',
                        text: '这个夏天来这里，带你看最美的湖。',
                        date: '2019年11月20日'
                    },
                    {
                        src: 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=952390237,2913690801&fm=15&gp=0.jpg',
                        text: '打着“飞的”去品尝玉树的美酒。',
                        date: '2019年11月20日'
                    },
                    {
                        src: 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=952390237,2913690801&fm=15&gp=0.jpg',
                        text: '打着“飞的”去游多情西海。',
                        date: '2019年11月20日'
                    },
                    {
                        src: 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=952390237,2913690801&fm=15&gp=0.jpg',
                        text: '打着“飞的”去看雪域高原。',
                        date: '2019年11月20日'
                    }
                ]
            }
        },
        methods: {
            
        }
    }
</script>
<style lang="scss">
    .tour-exep{
        .box{
            font-size:14px;  
            .text{
                -webkit-line-clamp:1;
                max-height:18px;
                overflow:hidden;
                margin:20px 20px 30px 10px;
            }
            .date{
                text-align:right;
                margin-right:13px;
            }
        }
    }

</style>