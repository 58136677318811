<template>
    <div class="history-hum">
        <el-row :gutter="12">
            <el-col :key="index" :span="6" v-for="(item, index) of dataTab">
                <div class="box" @click="detail(item.sjly,item.sjid)">
                    <img :src="item.ydlj==null?item.bdlj:item.ydlj" class="tumb"/>
                    <p class="title"><span>{{item.title}}</span></p>
<!--                    <p class="text">{{item.text}}</p>-->
                </div>
            </el-col>
        </el-row>
<!--        <pagination></pagination>-->
    </div>
</template>
<script>
    import pagination from '@/components/common/pagination'
    import {gettabData, getTabDataGroupByClass} from "../../../../api/homeService";

    export default {
        components: {
            pagination
        },
        props: {
            tabId: {
                type: String,
                default: () => ''
            }, zrzyId: {
                type: String,
                default: () => ''
            },  wbdz: {
                type: String,
                default: () => ''
            }
        },
        data() {
            return {
                dataTab: []
            }
        }, mounted() {
            this.getDyxxById()
        },
        methods: {
            getDyxxById() {

                const dy = {'zrzyid': this.zrzyId, 'id': this.tabId}
                gettabData(dy).then(res => {
                    if (res.code === '0') {
                        this.dataTab = res.data;
                    }
                })
            },
            /**
             * 根据当前单元号跳转
             * @param currentId
             */
            detail(type, currentId) {
                if (type === 'dyxx') {
                    this.$router.push({
                        name: 'infoDetail',
                        query: {
                          zrzyDyId: currentId
                        }
                    })
                }else{
                    this.$router.push({
                        name: 'newsDetail',
                        query: {
                            id:currentId
                        }
                    })
                }

            }
        }
    }
</script>
<style lang="scss">
    .history-hum {
        .box {
            color: #333;
            cursor: pointer;
            padding: 4px 4px 15px;
            margin-bottom: 12px;
            border: 1px solid #D9D9D9;
            height: 242px;
            .tumb {
                width: 100%;
                height: 160px;
                max-height: 160px;
            }

            .title {
                font-size: 18px;
                margin: 15px 5px 10px;
                line-height: 22px;
            }

            .text {
                margin: 0 5px;
                font-size: 14px;
                text-align: justify;
                line-height: 18px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }

</style>
